import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './css/index.css';
import AmeliaApp from './components/AmeliaApp';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { djangoApi } from './urls';
import { ProvideAuth, UserRole, UserRoleMap } from './auth';

function mapRoleIds(userRoles: string[]): UserRole[] {
    return userRoles.map((roleId) => {
        return { id: roleId, title: UserRoleMap[roleId] ?? roleId };
    });
}

const client = new ApolloClient({
    uri: djangoApi.graphql,
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    // Define policies per field type
                },
            },
            User: {
                fields: {
                    userRoles: {
                        read: mapRoleIds,
                    },
                },
            },
            UserProfile: {
                fields: {
                    userRoles: {
                        read: mapRoleIds,
                    },
                },
            },
            Course: {
                fields: {
                    // Define policies per field type
                    dateStart: {
                        read(dateStart: string) {
                            return dateStart ? new Date(dateStart) : null;
                        },
                    },
                    dateEnd: {
                        read(dateString: string) {
                            return dateString ? new Date(dateString) : null;
                        },
                    },
                    dateDeadline: {
                        read(dateString: string) {
                            return dateString ? new Date(dateString) : null;
                        },
                    },
                },
            },
            CourseDay: {
                fields: {
                    // Define policies per field type
                    dateDay: {
                        read(dateDay: string) {
                            return dateDay ? new Date(dateDay) : null;
                        },
                    },
                },
            },
            GuidanceGroup: {
                fields: {
                    // Define policies per field type
                    dateStart: {
                        read(dateStart: string) {
                            return dateStart ? new Date(dateStart) : null;
                        },
                    },
                    dateEnd: {
                        read(dateString: string) {
                            return dateString ? new Date(dateString) : null;
                        },
                    },
                },
            },
            GuidanceGroupDay: {
                fields: {
                    // Define policies per field type
                    dateDay: {
                        read(dateDay: string) {
                            return dateDay ? new Date(dateDay) : null;
                        },
                    },
                },
            },
            // Refers to the CommonFields type on a Case type.
            CommonFields: {
                fields: {
                    // Define policies per field type
                    dateStart: {
                        read(dateStart: string) {
                            return dateStart ? new Date(dateStart) : null;
                        },
                    },
                    dateEnd: {
                        read(dateString: string) {
                            return dateString ? new Date(dateString) : null;
                        },
                    },
                    dateBorn: {
                        read(dateString: string) {
                            return dateString ? new Date(dateString) : null;
                        },
                    },
                },
            },
            Certification: {
                fields: {
                    // Define policies per field type
                    dateStart: {
                        read(dateStart: string) {
                            return dateStart ? new Date(dateStart) : null;
                        },
                    },
                    dateEnd: {
                        read(dateEnd: string) {
                            return dateEnd ? new Date(dateEnd) : null;
                        },
                    },
                },
            },
            Message: {
                fields: {
                    // Define policies per field type
                    dateCreated: {
                        read(dateCreated: string) {
                            return dateCreated ? new Date(dateCreated) : null;
                        },
                    },
                },
            },
        },
    }),
});

function App() {
    return (
        <React.StrictMode>
            <ApolloProvider client={client}>
                <ProvideAuth>
                    <Router basename="/">
                        <AmeliaApp />
                    </Router>
                </ProvideAuth>
            </ApolloProvider>
        </React.StrictMode>
    );
}
export default App;
