import React, { useContext, createContext } from 'react';
import { useQuery, gql, ApolloError } from '@apollo/client';

/**
 * The authentication interface returned by useAuth().
 */
interface Auth {
    /**
     * The current user if logged in, otherwise null.
     */
    user: User | null;
    /**
     * True if the current user is being loaded, false if loading is completed.
     */
    loading: boolean;
    /**
     * Error details from Apollo, or undefined if there are no errors.
     */
    error?: ApolloError;
}

export interface UserRolesType {
    [index: string]: string;
    admin: string;
    'national-admin': string;
    'region-admin': string;
    practitioner: string;
    'tibir-coordinator': string;
}

export const UserRoleMap: UserRolesType = {
    admin: 'Administrator',
    'national-admin': 'Superbruker',
    'region-admin': 'Regionadministrator',
    practitioner: 'Utøver',
    'tibir-coordinator': 'TIBIR Koordinator',
};

/**
 * The authentication context which is shared throughout application.
 */
const authContext = createContext<Auth>({ user: null, loading: true });

/**
 * React component which provides the authentication context to all children.
 * Must be placed high up in the application element tree, but not before the
 * Apollo client provider.
 */
export function ProvideAuth({ children }: { children: JSX.Element }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

/**
 * React Hook which returns the current authentication context.
 * The context contains information about the currently logged in user (if any),
 * loading state and possible authentication errors.
 *
 * @returns Auth
 */
export const useAuth = (): Auth => {
    return useContext(authContext);
};

function useProvideAuth(): Auth {
    const userQuery = React.useMemo(
        () => gql`
            query CurrentUser {
                currentUser {
                    name
                    firstName
                    middleName
                    lastName
                    employer
                    serviceAreaText
                    certifications {
                        title
                        dateStart
                        dateEnd
                    }
                    userRoles
                }
            }
        `,
        []
    );
    const { data, loading, error } = useQuery<CurrentUserQueryData>(userQuery);
    const user = data?.currentUser || null;

    return {
        user,
        loading,
        error,
    };
}

/**
 * A single certification
 */
export interface Certification {
    title: string;
    dateStart: Date;
    dateEnd?: Date;
}

/**
 * A user's role
 */
export interface UserRole {
    id: string;
    title: string;
}

/**
 * The User model for the currently logged in user.
 */
export interface User {
    name: string;
    firstName: string;
    middleName: string | null;
    lastName: string;
    employer: string;
    serviceAreaText: string;
    certifications: Certification[];
    userRoles: UserRole[];
}

interface CurrentUserQueryData {
    currentUser: User | null;
}
