import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Divider, ListItemIcon } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import IconButton from '@material-ui/core/IconButton';
import { LockOpen } from '@material-ui/icons';
import AccountBox from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TuneIcon from '@material-ui/icons/Tune';
import HelpIcon from '@material-ui/icons/Help';

import { djangoApi, urls } from '../urls';
import { useAuth } from '../auth';
import { useStyles } from '../styles';

export function AmeliaUserMenu() {
    const classes = useStyles();
    const { user } = useAuth();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const userInitials = user ? user.firstName[0] + user.lastName[0] : '';

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    const handleLogin = (event: React.MouseEvent<EventTarget>) => {
        setOpen(false);
        window.location.href = djangoApi.login;
    };

    const handleLogout = (event: React.MouseEvent<EventTarget>) => {
        setOpen(false);
        window.location.href = djangoApi.logout;
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab' || event.key === 'Escape') {
            event.preventDefault();
            setOpen(false);
        }
    }

    return (
        <>
            <div className={classes.grow} />
            {!user ? (
                <>
                    <Button onClick={handleLogin}>
                        <LockOpen /> Logg inn
                    </Button>
                    <IconButton
                        edge="end"
                        aria-label="Brukermeny"
                        title="Ikke logget inn"
                        //   aria-controls={menuId}
                        color="inherit"
                        onClick={handleLogin}
                    >
                        <Avatar></Avatar>
                    </IconButton>
                </>
            ) : (
                <>
                    <IconButton aria-label="Hjelp" color="inherit" title="Åpne brukermanualen">
                        <HelpIcon />
                    </IconButton>
                    <IconButton
                        ref={anchorRef}
                        edge="end"
                        aria-label="account of current user"
                        //   aria-controls={menuId}
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleToggle}
                    >
                        <Avatar alt={user.name} title={user.name} className={classes.orange}>
                            {userInitials}
                        </Avatar>
                    </IconButton>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={open}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                        >
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar></Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={user.name} secondary={user.serviceAreaText} />
                                            </ListItem>

                                            <Divider />

                                            <MenuItem onClick={handleClose} component={Link} to={urls.userProfile}>
                                                <ListItemIcon>
                                                    <AccountBox />
                                                </ListItemIcon>
                                                Profil
                                            </MenuItem>
                                            <MenuItem onClick={handleClose} component={Link} to={urls.userPassword}>
                                                <ListItemIcon>
                                                    <TuneIcon />
                                                </ListItemIcon>
                                                Endre passord
                                            </MenuItem>

                                            <Divider />

                                            <MenuItem disabled={true}>
                                                <ListItemIcon aria-label="Hjelp">
                                                    <HelpIcon />
                                                </ListItemIcon>
                                                Hjelp
                                            </MenuItem>

                                            <Divider />

                                            <MenuItem onClick={handleLogout}>
                                                <ListItemIcon>
                                                    <ExitToAppIcon />
                                                </ListItemIcon>
                                                Logg ut
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </>
            )}
        </>
    );
}

export default AmeliaUserMenu;
