import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Toolbar } from '@material-ui/core';

import AmeliaSideMenu from './AmeliaSideMenu';
import AmeliaAppBar from './AmeliaAppBar';
import AmeliaRouteNotFound from './AmeliaRouteNotFound';
import urls from '../urls';
import { useStyles } from '../styles';

const AmeliaUserProfileForm = lazy(() => import('./AmeliaUserProfileForm'));
const AmeliaUserPasswordForm = lazy(() => import('./AmeliaUserPasswordForm'));
const AmeliaCertificationsTable = lazy(() => import('./AmeliaCertificationsTable'));

const AmeliaCoursePanel = lazy(() => import('./AmeliaCoursePanel'));
const AmeliaCourseTable = lazy(() => import('./AmeliaCourseTable'));
const AmeliaAttendedCoursePanel = lazy(() => import('./AmeliaAttendedCoursePanel'));
const AmeliaAttendedCourseTable = lazy(() => import('./AmeliaAttendedCourseTable'));
const AmeliaGuidancePanel = lazy(() => import('./AmeliaGuidancePanel'));
const AmeliaGuidanceTable = lazy(() => import('./AmeliaGuidanceTable'));
const AmeliaAttendedGuidancePanel = lazy(() => import('./AmeliaAttendedGuidancePanel'));
const AmeliaAttendedGuidanceTable = lazy(() => import('./AmeliaAttendedGuidanceTable'));
const AmeliaMaterials = lazy(() => import('./AmeliaMaterials'));
const AmeliaMessagePanel = lazy(() => import('./AmeliaMessagePanel'));
const AmeliaCaseTable = lazy(() => import('./AmeliaCaseTable'));
const AmeliaCaseForm = lazy(() => import('./AmeliaCaseForm'));

const AmeliaCourseForm = lazy(() => import('./AmeliaCourseForm'));
const AmeliaAdminGuidanceTable = lazy(() => import('./AmeliaAdminGuidanceTable'));
const AmeliaAdminUsersTable = lazy(() => import('./AmeliaAdminUsersTable'));
const AmeliaAdminCourseTable = lazy(() => import('./AmeliaAdminCourseTable'));

const AmeliaDevInfo = lazy(() => import('./AmeliaDevInfo'));

function Home() {
    return <h2>Velkommen til Amelia</h2>;
}

function AmeliaApp() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <AmeliaAppBar />
            <AmeliaSideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        {/* Only used in development */}
                        <Route path={urls.devInfo} element={<AmeliaDevInfo />}  />

                        {/* Personal information */}
                        <Route path={urls.userProfile} element={<AmeliaUserProfileForm />}  />

                        {/* Certifications */}
                        <Route path={urls.certifications} element={<AmeliaCertificationsTable />}  />

                        {/* Role-based information */}
                        <Route path={urls.materialIndex} element={<AmeliaMaterials />}  />

                        {/* Global information */}
                        <Route path={urls.messageIndex} element={<AmeliaMessagePanel />}  />

                        {/* Available cases + editing */}
                        <Route path={urls.caseIndex} element={<AmeliaCaseTable />}  />
                        <Route path={urls.caseEdit} element={<AmeliaCaseForm />}  />

                        {/* Available courses/guidance groups */}
                        <Route path={urls.courseIndex} element={<AmeliaCourseTable />}  />
                        <Route path={urls.course} element={<AmeliaCoursePanel />}  />
                        <Route path={urls.guidanceIndex} element={<AmeliaGuidanceTable />}  />
                        <Route path={urls.guidance} element={<AmeliaGuidancePanel />}  />

                        {/* Attended courses/guidance groups */}
                        <Route path={urls.attendedCourseIndex} element={<AmeliaAttendedCourseTable />}  />
                        <Route path={urls.attendedCourse} element={<AmeliaAttendedCoursePanel />}  />
                        <Route path={urls.attendedGuidanceIndex} element={<AmeliaAttendedGuidanceTable />}  />
                        <Route path={urls.attendedGuidance} element={<AmeliaAttendedGuidancePanel />}  />

                        {/* Region administration */}
                        <Route path={urls.courseEdit} element={<AmeliaCourseForm />}  />
                        <Route path={urls.adminCourseIndex} element={<AmeliaAdminCourseTable />}  />
                        <Route path={urls.adminGuidanceIndex} element={<AmeliaAdminGuidanceTable />}  />
                        <Route path={urls.adminUsersIndex} element={<AmeliaAdminUsersTable />}  />

                        {/* Start page */}
                        <Route path={urls.home} element={<Home />}  />
                        <Route element={<AmeliaRouteNotFound />} />
                    </Routes>
                </Suspense>
            </main>
        </div>
    );
}

export default AmeliaApp;
