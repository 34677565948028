import {
    Toolbar,
    Drawer,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    makeStyles,
    createStyles,
    Theme,
} from '@material-ui/core';
import ListSubheader from '@material-ui/core/ListSubheader';
import Collapse from '@material-ui/core/Collapse';

import { Info, Event, Assignment } from '@material-ui/icons';
import Folder from '@material-ui/icons/Folder';
import PeopleIcon from '@material-ui/icons/People';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import React from 'react';
import { Link } from 'react-router-dom';
import urls from '../urls';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerContainer: {
            overflow: 'auto',
        },
    })
);

export function AmeliaSideMenu() {
    const classes = useStyles();

    const [coursesOpen, setCoursesOpen] = React.useState(true);
    const [guidanceGroupOpen, setGuidanceGroupOpen] = React.useState(true);

    const handleCoursesClick = () => {
        setCoursesOpen(!coursesOpen);
    };
    const handleGuidanceGroupClick = () => {
        setGuidanceGroupOpen(!guidanceGroupOpen);
    };

    // Kurs: Kursleder
    // Kurs: Veileder
    // Mine veiledninger
    //  - PMTO-Terapeut
    //  - Foreldrerådgiver
    //  - Foreldregruppe
    //  - Støtte til mestring
    // Aktive Saker
    //  - PMTO-Terapaut
    //  - Foreldrerådgiver
    //  - Foreldregruppe
    //  - Støtte til mestring
    // Avsluttede Saker
    //  - PMTO-Terapaut
    //  - Foreldrerådgiver
    //  - Foreldregruppe
    //  - Støtte til mestring
    // Materiell

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="left"
        >
            <Toolbar />
            <div className={classes.drawerContainer}>
                <List>
                    <ListItem button key="Kurs" component={Link} to={urls.courseIndex} onClick={handleCoursesClick}>
                        <ListItemIcon>
                            <Event />
                        </ListItemIcon>
                        <ListItemText primary="Kurs" />
                        {coursesOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={coursesOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button key="Påmeldte kurs" component={Link} to={urls.attendedCourseIndex}>
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="Påmeldte kurs" />
                            </ListItem>
                            <ListItem button key="Tilgjengelige kurs" component={Link} to={urls.courseIndex}>
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="Tilgjengelige kurs" />
                            </ListItem>
                        </List>
                    </Collapse>

                    <ListItem
                        button
                        key="Veiledningsgrupper"
                        component={Link}
                        to={urls.courseIndex}
                        onClick={handleGuidanceGroupClick}
                    >
                        <ListItemIcon>
                            <Event />
                        </ListItemIcon>
                        <ListItemText primary="Veiledningsgrupper" />
                        {guidanceGroupOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={guidanceGroupOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem
                                button
                                key="Påmeldte veiledningsgrupper"
                                component={Link}
                                to={urls.attendedGuidanceIndex}
                            >
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="Påmeldte veiledningsgrupper" />
                            </ListItem>
                            <ListItem
                                button
                                key="Tilgjengelige veiledningsgrupper"
                                component={Link}
                                to={urls.guidanceIndex}
                            >
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="Tilgjengelige veiledningsgrupper" />
                            </ListItem>
                        </List>
                    </Collapse>

                    <ListItem button key="Alle Saker" component={Link} to={urls.caseIndex}>
                        <ListItemIcon>
                            <Assignment />
                        </ListItemIcon>
                        <ListItemText primary="Alle Saker" />
                    </ListItem>

                    <ListItem button key="Sertifiseringer" component={Link} to={urls.certifications}>
                        <ListItemIcon>
                            <CardMembershipIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sertifiseringer" />
                    </ListItem>

                    <ListItem button key="Materiell" component={Link} to={urls.materialIndex}>
                        <ListItemIcon>
                            <Folder />
                        </ListItemIcon>
                        <ListItemText primary="Materiell" />
                    </ListItem>

                    <ListItem button key="Meldinger" component={Link} to={urls.messageIndex}>
                        <ListItemIcon>
                            <AnnouncementIcon />
                        </ListItemIcon>
                        <ListItemText primary="Meldinger" />
                    </ListItem>
                </List>

                <List subheader={<ListSubheader>Regionsadministrasjon</ListSubheader>}>
                    <ListItem button key="Kurs" component={Link} to={urls.adminCourseIndex}>
                        <ListItemIcon>
                            <Event />
                        </ListItemIcon>
                        <ListItemText primary="Kurs" />
                    </ListItem>
                    <ListItem button key="Veiledningsgrupper" component={Link} to={urls.adminGuidanceIndex}>
                        <ListItemIcon>
                            <Event />
                        </ListItemIcon>
                        <ListItemText primary="Veiledningsgrupper" />
                    </ListItem>
                    <Divider />
                    <ListItem button key="Brukere" component={Link} to={urls.adminUsersIndex}>
                        <ListItemIcon>
                            <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Brukere" />
                    </ListItem>
                </List>

                <List subheader={<ListSubheader>Development</ListSubheader>}>
                    <ListItem button key="Dev Info" component={Link} to={urls.devInfo}>
                        <ListItemIcon>
                            <Info />
                        </ListItemIcon>
                        <ListItemText primary="Dev Info" />
                    </ListItem>
                </List>
            </div>
        </Drawer>
    );
}

export default AmeliaSideMenu;
