import {envName, backendUrl, frontendUrl, legacyUrl, apiUrl} from './env';
console.log("config.tsx");
console.log('backendUrl', backendUrl)

function getCookie(key: string): string | null {
    function convertPart(value: string) {
        return value.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent);
    }

    if (typeof document === 'undefined' || (arguments.length && !key)) {
        return null;
    }

    // To prevent the for loop in the first place assign an empty array
    // in case there are no cookies at all.
    let cookies: string[] = document.cookie ? document.cookie.split('; ') : [];
    let jar: any = {};
    for (let i = 0; i < cookies.length; i++) {
        let parts = cookies[i].split('=');
        let value = parts.slice(1).join('=');

        if (value[0] === '"') {
            value = value.slice(1, -1);
        }

        try {
            let foundKey = convertPart(parts[0]);
            jar[foundKey] = convertPart(value);

            if (key === foundKey) {
                break;
            }
        } catch (e) {}
    }

    return key ? jar[key] : jar;
}

export { envName, backendUrl, frontendUrl, legacyUrl, apiUrl, getCookie };
