import { AppBar, createStyles, makeStyles, Theme, Toolbar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import urls from '../urls';
import AmeliaUserMenu from './AmeliaUserMenu';
import { ReactComponent as Logo } from '../AmeliaLogo.svg';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
        },
        toolbar: {
            justifyContent: 'space-between',
        },
    })
);

export function AmeliaAppBar() {
    const classes = useStyles();

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <Link to={urls.home}>
                    <Logo />
                </Link>
                <AmeliaUserMenu />
            </Toolbar>
        </AppBar>
    );
}

export default AmeliaAppBar;
