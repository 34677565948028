import { apiUrl } from './config';

/**
 * Urls for users who are adminstrators.
 */
export const adminUrls = {
    adminCourseIndex: '/admin/courses/',
    adminGuidanceIndex: '/admin/guidance/',
    adminUsersIndex: '/admin/users/',
    courseEdit: '/course_edit/:id([0-9]+)/',
    guidanceEdit: '/guidance_edit/:id([0-9]+)/',
};

/**
 * Urls that are only accessible during development.
 */
export const devUrls = {
    devInfo: '/dev_info/',
};

/**
 * Urls related to the currently logged in user.
 */
export const personalUrls = {
    userProfile: '/user/profile',
    userPassword: '/user/password',
};

export const urls = {
    ...adminUrls,
    ...devUrls,
    ...personalUrls,

    courseIndex: '/courses/',
    course: '/courses/:id([0-9]+)/',
    attendedCourseIndex: '/attended_courses/',
    attendedCourse: '/attended_course/:id([0-9]+)/',
    guidanceIndex: '/guidances/',
    guidance: '/guidance/:id([0-9]+)/',
    attendedGuidanceIndex: '/attended_guidances/',
    attendedGuidance: '/attended_guidance/:id([0-9]+)/',
    materialIndex: '/materials/',
    messageIndex: '/messages/',
    caseIndex: '/cases/',
    caseEdit: '/case_edit/:id([0-9]+)/',
    certifications: '/certifications/',
    home: '/',
};

export const djangoApi = {
    login: apiUrl + '/login/',
    logout: apiUrl + '/logout/',
    graphql: apiUrl + '/graphql/',
};

export default urls;
