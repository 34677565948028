import { useLocation } from 'react-router';

export function NotFound() {
    const location = useLocation();
    return (
        <p>
            Route not found for location: <pre>{JSON.stringify(location)}</pre>
        </p>
    );
}

export default NotFound;
